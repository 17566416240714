
import * as echarts from "echarts";
import { ball } from "@/assets/echarts";

import getClassColor from "@/utils/getClassName";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    AllData: {
      type: Object,
    },
    list: {
      type: Array,
    },
  },
  setup(props) {
    const balls = ref();
    const data = reactive({
      ballData: ball(),
    });

    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      setTimeout(() => {
        if (props?.AllData?.id == "1000014") {
          props?.AllData.dataValues[0].dataItemValues.forEach((item: any) => {
            if (item.metaDataItemId == "1000015210") {
              data.ballData.title[0].text = item.title;
              data.ballData.series[0].data = [item.value, item.value];
            }
          });
          publicEcharts(balls?.value[0], data.ballData);
        }
      }, 500);
    });

    // 公共echarts
    const publicEcharts = (Dom: any, Data: any) => {
      let myChart = echarts.init(Dom);
      myChart.setOption(Data);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };

    const getTitleShow = (extJson: any) => {
      let type_show = true;
      if (extJson) {
        type_show = JSON.parse(extJson)?.showType == 1 ? false : true;
      }
      return type_show;
    };
    return {
      ...toRefs(data),
      getClassColor,
      getTitleShow,
      balls,
    };
  },
});
