import * as echarts from "echarts";
const Line = () => {
  return {
    option: {
      backgroundColor: "",
      color: ["#7CFFB2", "skyblue"],
      xAxis: {
        type: "category",
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      grid: {
        x: 30,
        y: 20,
        x2: 0,
        y2: 30,
        bottom: "1%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        // formatter: "{a} <br/>{b} : {c}",
      },
      legend: {
        data: ["设定温度", "运行温度"],
        textStyle: {
          color: "#fff",
        },
      },
      yAxis: {
        type: "value",
        max: 1400,
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      series: [
        {
          name: "设定温度",
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: "line",
          smooth: true,
        },
        {
          name: "运行温度",
          data: [8, 632, 701, 634, 990, 230, 920],
          type: "line",
          smooth: true,
        },
      ],
    },
  };
};

const Yield = () => {
  return {
    option: {
      backgroundColor: "transparent",
      title: {
        text: "今日产量",
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        textStyle: {
          color: "#fff",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      yAxis: {
        type: "category",
        data: ["工件"],

        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
      series: [
        {
          name: "上件",
          type: "bar",

          data: [18203],
        },
        {
          name: "下件",
          type: "bar",
          data: [19325],
        },
      ],
    },
  };
};

const Motor = () => {
  return {
    // backgroundColor: "#00265f",
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    title: {
      text: "抛丸电机频率",
      top: "0%",
      textStyle: {
        color: "#fff",
        fontSize: 16,
      },
    },
    grid: {
      top: "15%",
      right: "3%",
      left: "5%",
      bottom: "12%",
    },
    xAxis: [
      {
        type: "category",
        data: ["电机1", "电机2", "电机3", "电机4", "电机5", "电机6", "电机7"],
        axisLine: {
          lineStyle: {
            color: "rgba(255,255,255,0.12)",
          },
        },
        axisLabel: {
          margin: 10,
          color: "#e2e9ff",
          textStyle: {
            fontSize: 14,
          },
        },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          formatter: "{value}",
          color: "#e2e9ff",
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: "rgba(255,255,255,0.12)",
          },
        },
      },
    ],
    series: [
      {
        type: "bar",
        data: [300, 450, 770, 203, 255, 188, 156],
        barWidth: "20px",
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(0,244,255,1)", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(0,77,167,1)", // 100% 处的颜色
                },
              ],
              false
            ),
            barBorderRadius: [30, 30, 30, 30],
            shadowColor: "rgba(0,160,221,1)",
            shadowBlur: 4,
          },
        },
        // label: {
        //     normal: {
        //         show: true,
        //         lineHeight: 30,
        //         width: 80,
        //         height: 30,
        //         backgroundColor: 'rgba(0,160,221,0.1)',
        //         borderRadius: 200,
        //         position: ['-8', '-60'],
        //         distance: 1,
        //         formatter: [
        //             '    {d|●}',
        //             ' {a|{c}}     \n',
        //             '    {b|}'
        //         ].join(','),
        //         rich: {
        //             d: {
        //                 color: '#3CDDCF',
        //             },
        //             a: {
        //                 color: '#fff',
        //                 align: 'center',
        //             },
        //             b: {
        //                 width: 1,
        //                 height: 30,
        //                 borderWidth: 1,
        //                 borderColor: '#234e6c',
        //                 align: 'left'
        //             },
        //         }
        //     }
        // }
      },
    ],
  };
};

const orSocopy = () => {
  return {
    title: {
      text: "加热炉区分温度",
      top: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 16,
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
    },
    legend: {
      x: "65%",
      top: "0%",
      textStyle: {
        color: "#90979c",
      },
      data: ["设定温度", "运行温度"],
    },
    grid: [
      {
        x: 1,
        show: false,
        top: "20%",
        bottom: "8%",
        containLabel: true,
        height: "35%",
      },
      {
        show: false,
        top: "55%",
        bottom: "8%",
        height: "0%",
        left: "3%",
      },
      {
        x: 1,
        show: false,
        bottom: "0%",
        containLabel: true,
        height: "35%",
      },
    ],
    xAxis: [
      {
        type: "category",
        position: "top",

        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          inverse: false,
          offset: -10,
          axisTick: "none",
          axisLine: "none",
          show: true,
          textStyle: {
            color: "#ffffff",
            fontSize: "12",
          },
        },
        splitLine: {
          show: false,
        },
        data: [],
      },
      {
        gridIndex: 1,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },

        splitLine: {
          show: false,
        },
        data: [
          "T1",
          "T2",
          "T3",
          "T4",
          "T5",
          "T6",
          "T7",
          "T8",
          "T9",
          "T10",
          "T11",
          "T12",
          "T13",
          "T14",
          "T15",
          "T16",
        ],
        axisLabel: {
          color: "#fff",
        },
      },
      {
        gridIndex: 2,
        type: "category",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          inverse: false,
          offset: -10,
          axisTick: "none",
          axisLine: "none",
          show: true,
          textStyle: {
            color: "#ffffff",
            fontSize: "12",
          },
        },
        splitLine: {
          show: false,
        },
        data: [],
      },
    ],
    yAxis: [
      {
        type: "value",
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,

            type: "solid",
          },
        },
      },

      {
        gridIndex: 1,
        type: "category",
        inverse: true,
        axisLine: {
          show: true,
        },

        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      {
        gridIndex: 2,
        type: "value",
        inverse: true,
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 0,
            type: "solid",
          },
        },
      },
    ],
    series: [
      {
        name: "运行温度",
        type: "bar",
        barWidth: 20,
        stack: "1",
        data: [
          10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160,
        ],
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#71FFFF",
              },
              {
                offset: 1,
                color: "#3DFFFF",
              },
            ]),
            barBorderRadius: [12, 12, 0, 0],
            // label: {
            //   show: true, //开启显示
            //   position: "top", //在上方显示
            //   textStyle: {
            //     //数值样式
            //     color: "#fff",
            //     fontSize: 16,
            //   },
            // },
          },
        },
      },

      {
        name: "数据1",
        type: "line",

        data: [
          10, 19, 40, 50, 60, 70, 80, 90, 50, 110, 120, 130, 140, 150, 160, 170,
        ],
      },
      {
        name: "数据2",

        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#71FFFF",
              },
              {
                offset: 1,
                color: "#3DFFFF",
              },
            ]),
            barBorderRadius: [0, 0, 12, 12],
          },
        },

        type: "bar",
        xAxisIndex: 2,
        yAxisIndex: 2,
        barWidth: 20,
        stack: "2",
        data: [
          10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160,
        ],
      },

      {
        name: "设定温度",
        type: "line",
        xAxisIndex: 2,
        yAxisIndex: 2,
        Symbol: "circle",

        data: [
          20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160,
          170,
        ],
      },
    ],
  };
};

const orSo = () => {
  return {
    title: {
      text: "加热炉区分温度",
      top: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: [
      {
        top: 40,
        bottom: "48%",
        left: 10,
        right: 20,
        containLabel: true,
      },
      {
        top: "54%",
        bottom: 20,
        left: 10,
        right: 20,
        containLabel: true,
      },
    ],
    xAxis: [
      {
        show: true,
        type: "category",
        // position: "top",
        axisTick: { show: false },
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
        data: [],
      },
      {
        show: true,
        type: "category",
        // position: "bottom",
        gridIndex: 1,
        axisTick: { show: false },
        // axisLabel: { show: true },
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
        data: [],
      },
      {
        show: true,
        type: "category",
        // position: "top",
        gridIndex: 1,
        axisTick: { show: false },
        // axisLabel: { show: true },
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
        data: [],
      },
      // {
      //   show: true,
      //   type: "category",
      //   position: "top",
      //   gridIndex: 2,
      //   axisTick: { show: false },
      //   axisLabel: { show: false },
      //   textStyle: {
      //     color: "#ffffff",
      //     fontSize: "12",
      //   },

      //   data: [],
      // },
    ],
    yAxis: [
      {
        show: true,
        type: "value",

        splitNumber: 4,
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
      },
      {
        show: true,
        type: "value",
        splitNumber: 4,

        gridIndex: 1,
        inverse: true,
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
      },
    ],
    series: [
      {
        name: "运行温度",
        type: "bar",
        z: 3,
        barWidth: "40%",
        data: [],
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#71FFFF",
              },
              {
                offset: 1,
                color: "#3DFFFF",
              },
            ]),
            barBorderRadius: [12, 12, 0, 0],
          },
        },
      },
      {
        name: "设定温度",
        type: "line",

        data: [
          10, 19, 40, 50, 60, 70, 80, 90, 50, 110, 120, 130, 140, 150, 160, 170,
        ],
      },
      {
        name: "运行温度",
        type: "bar",
        xAxisIndex: 1,
        yAxisIndex: 1,
        z: 3,
        barWidth: "40%",
        data: [],
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#71FFFF",
              },
              {
                offset: 1,
                color: "#3DFFFF",
              },
            ]),
            barBorderRadius: [0, 0, 12, 12],
          },
        },
      },
      {
        name: "设定温度",
        type: "line",
        xAxisIndex: 1,
        yAxisIndex: 1,
        Symbol: "circle",
        data: [
          20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160,
          170,
        ],
      },
    ],
  };
};

// 球形波浪
// import "echarts-liquidfill/src/liquidFill.js";
import "echarts-liquidfill";
const ball = () => {
  let value1 = 0.16;
  return {
    title: [
      {
        // text: "本年收缴率",
        text: "1",
        x: "center",
        y: "70%",
        textStyle: {
          fontSize: 11,
          fontWeight: "100",
          color: "#fff",
          lineHeight: 16,
          textAlign: "center",
        },
      },
    ],
    series: [
      {
        type: "liquidFill",
        radius: "47%",
        center: ["50%", "45%"],
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#2aa1e3",
              },
              {
                offset: 1,
                color: "#08bbc9",
              },
            ],
            globalCoord: false,
          },
        ],
        data: [value1, value1], // data个数代表波浪数
        backgroundStyle: {
          borderWidth: 1,
          color: "RGBA(0, 216, 245, 0.34)",
        },
        label: {
          normal: {
            textStyle: {
              fontSize: 14,
              color: "#fff",
            },
          },
        },
        outline: {
          // show: false
          borderDistance: 0,
          itemStyle: {
            borderWidth: 2,
            borderColor: "#02D8F5",
          },
        },
      },
    ],
  };
};

// 升温曲线
const warming = () => {
  let xLabel = [
    "T1",
    "T2",
    "T3",
    "T4",
    "T5",
    "T6",
    "T7",
    "T8",
    "T9",
    "T10",
    "T11",
    "T12",
    "T13",
    "T14",
    "T15",
    "T16",
    "出炉",
    "其他",
  ];
  let valueData = [
    810, 960, 1020, 1080, 1105, 1110, 1110, 1150, 1000, 1020, 1050, 980, 1000,
    1020, 1150, 1190, 1195, 800,
  ];
  return {
    title: {
      text: "升温降温曲线",
      top: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 16,
      },
    },
    tooltip: {
      backgroundColor: "",
      textStyle: {
        color: "#fff",
      },
      padding: [10, 10],
      extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)",
      trigger: "axis",
      axisPointer: {
        lineStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(29, 145, 245, 1)", // 0% 处的颜色
              },
              {
                offset: 0.5,
                color: "rgba(29, 145, 245, 1)", // 100% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(29, 145, 245, 1)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
          width: 2,
          type: "solid",
        },
      },
    },
    grid: {
      top: "15%",
      left: "6%",
      right: "4%",
      bottom: "15%",
    },
    xAxis: [
      {
        type: "category",
        data: xLabel,
        axisLine: {
          lineStyle: {
            color: "rgba(203, 220, 236, 1)",
            width: 1,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            color: "#fff",
          },
          // rotate: "-90",
          fontSize: 15,
          margin: 15,
        },
        boundaryGap: false,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: "#fff",
            fontSize: 15,
          },
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: "数据",
        type: "line",
        data: valueData,
        symbolSize: 14,
        symbol: "circle",
        smooth: true,
        yAxisIndex: 0,
        showSymbol: false,

        lineStyle: {
          width: 2,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "RGBA(255, 224, 0, 0.55)",
            },
            {
              offset: 1,
              color: "RGBA(255, 190, 0, 0)",
            },
          ]),
          shadowColor: "RGBA(165, 214, 255, 1)",
          shadowBlur: 10,
          shadowOffsetY: 10,
        },
        itemStyle: {
          color: "#fff",
          normal: {
            // color: "rgba(29, 145, 245, 1)",
            color: "#fff",
            borderColor: "#fff",
            borderWidth: 3,
          },
        },
        areaStyle: {
          //区域填充样式
          normal: {
            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "RGBA(255, 224, 0, 0.55)",
                },
                {
                  offset: 0.6,
                  color: "RGBA(255, 224, 0, 0.20)",
                },
                {
                  offset: 1,
                  color: " RGBA(255, 190, 0, 0)",
                },
              ],
              false
            ),
            shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
          },
        },
      },
    ],
  };
};

// 抛丸喷油
const paowan = () => {
  const CubeLeft = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
    },
    buildPath: function (ctx: any, shape) {
      const xAxisPoint = shape.xAxisPoint;
      const c0 = [shape.x, shape.y];
      const c1 = [shape.x - 9, shape.y - 9];
      const c2 = [xAxisPoint[0] - 9, xAxisPoint[1] - 9];
      const c3 = [xAxisPoint[0], xAxisPoint[1]];
      ctx
        .moveTo(c0[0], c0[1])
        ?.lineTo(c1[0], c1[1])
        .lineTo(c2[0], c2[1])
        .lineTo(c3[0], c3[1])
        .closePath();
    },
  });
  const CubeRight = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
    },
    buildPath: function (ctx: any, shape) {
      const xAxisPoint = shape.xAxisPoint;
      const c1 = [shape.x, shape.y];
      const c2 = [xAxisPoint[0], xAxisPoint[1]];
      const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9];
      const c4 = [shape.x + 18, shape.y - 9];
      ctx
        .moveTo(c1[0], c1[1])
        ?.lineTo(c2[0], c2[1])
        .lineTo(c3[0], c3[1])
        .lineTo(c4[0], c4[1])
        .closePath();
    },
  });
  const CubeTop = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
    },
    buildPath: function (ctx: any, shape: any) {
      const c1 = [shape.x, shape.y];
      const c2 = [shape.x + 18, shape.y - 9];
      const c3 = [shape.x + 9, shape.y - 18];
      const c4 = [shape.x - 9, shape.y - 9];
      ctx
        .moveTo(c1[0], c1[1])
        ?.lineTo(c2[0], c2[1])
        .lineTo(c3[0], c3[1])
        .lineTo(c4[0], c4[1])
        .closePath();
    },
  });
  echarts.graphic.registerShape("CubeLeft", CubeLeft);
  echarts.graphic.registerShape("CubeRight", CubeRight);
  echarts.graphic.registerShape("CubeTop", CubeTop);
  const MAX = [
    6000, 6000, 6000, 6000, 6000, 5000, 4000, 3000, 2000, 4000, 3000, 2000,
  ];
  const VALUE = [
    700, 1230, 3790, 2349, 1654, 1230, 3790, 2349, 1654, 3790, 2349, 1654,
  ];
  return {
    backgroundColor: "",
    title: {
      text: "抛丸电机频率",
      top: "3%",
      textStyle: {
        color: "#fff",
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params: any, ticket: any, callback: any) {
        const item = params[1];
        return item.name + " : " + item.value;
      },
    },

    grid: {
      left: 20,
      right: 40,
      bottom: "19%",
      top: 60,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [
        "德州",
        "德城区",
        "陵城区",
        "禹城市",
        "乐陵市",
        "临邑县",
        "平原县",
        "夏津县",
        "武城县",
        "庆云县",
        "宁津县",
        "齐河县",
      ],
      axisLine: {
        show: true,
        lineStyle: {
          color: "white",
        },
      },
      offset: 20,
      axisTick: {
        show: false,
        length: 9,
        alignWithLabel: true,
        lineStyle: {
          color: "#7DFFFD",
        },
      },
      axisLabel: {
        fontSize: 10,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: true,
        lineStyle: {
          color: "white",
        },
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 16,
      },
      boundaryGap: ["0%", "8%"],
    },
    series: [
      {
        type: "custom",
        renderItem: function (params: any, api: any) {
          const location = api.coord([api.value(0), api.value(1)]);
          return {
            type: "group",
            children: [
              {
                type: "CubeLeft",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: "rgba(47,102,192,.27)",
                },
              },
              {
                type: "CubeRight",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: "rgba(59,128,226,.27)",
                },
              },
              {
                type: "CubeTop",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: "rgba(72,156,221,.27)",
                },
              },
            ],
          };
        },
        data: MAX,
      },
      {
        type: "custom",
        renderItem: (params: any, api: any) => {
          const location = api.coord([api.value(0), api.value(1)]);
          return {
            type: "group",
            children: [
              {
                type: "CubeLeft",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#3B80E2",
                    },
                    {
                      offset: 1,
                      color: "#49BEE5",
                    },
                  ]),
                },
              },
              {
                type: "CubeRight",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#3B80E2",
                    },
                    {
                      offset: 1,
                      color: "#49BEE5",
                    },
                  ]),
                },
              },
              {
                type: "CubeTop",
                shape: {
                  api,
                  xValue: api.value(0),
                  yValue: api.value(1),
                  x: location[0],
                  y: location[1],
                  xAxisPoint: api.coord([api.value(0), 0]),
                },
                style: {
                  fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#3B80E2",
                    },
                    {
                      offset: 1,
                      color: "#49BEE5",
                    },
                  ]),
                },
              },
            ],
          };
        },
        data: VALUE,
      },
      {
        type: "bar",
        label: {
          normal: {
            show: true,
            position: "top",
            formatter: (e: any) => {
              switch (e.name) {
                case "10kV线路":
                  return VALUE[0];
                case "公用配变":
                  return VALUE[1];
                case "35kV主变":
                  return VALUE[2];
                case "水":
              }
            },
            fontSize: 16,
            color: "#fff",
            offset: [4, -25],
          },
        },
        itemStyle: {
          color: "transparent",
        },
        data: MAX,
      },
    ],
  };
};
export { Line, Yield, Motor, orSo, ball, warming, paowan };
