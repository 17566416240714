import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-110e480c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "box"
}
const _hoisted_3 = { class: "row al-c ju-c" }
const _hoisted_4 = { class: "mtop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.AllData.echratsShow ? 'wds' : '', "flex"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        style: _normalizeStyle({ width: _ctx.AllData.flexwd }),
        key: item.metaDataItemId
      }, [
        (item.metaDataItemId == '1000015210')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "ball_box",
                ref_for: true,
                ref: "balls"
              }, null, 512)
            ]))
          : _createCommentVNode("", true),
        (item.metaDataItemId != '1000015210')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", {
                  class: _normalizeClass(["num", _ctx.getClassColor(item.value)])
                }, _toDisplayString(item.value), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(["num", _ctx.getClassColor(item.value)])
                }, _toDisplayString(item.unit), 3)
              ]),
              _createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1)
            ]))
          : _createCommentVNode("", true)
      ], 4))
    }), 128))
  ], 2))
}